import Vue from 'vue';
import Component from 'vue-class-component';
import { DUNATI_DOMAIN, DUNATI_ORGANIZATION_SCHEMA, ROBOT_CONTENT } from '@/main';

interface AboutUser {
  name: string;
  position: string;
  position1: string;
  img: string;
}

@Component({})
export default class AboutUsComponent extends Vue {

  public vHeight = 0;
  public activeIndex = null;

  public listUsers: AboutUser[] = [{
    name: 'Leonardo Bianchi',
    position: 'Ingeniero Industrial',
    position1: 'Gerente General',
    img: '/assets/images/about-us/team/leonardo-bianchi.jpg',
  }, {
    name: 'Carlos Harbie',
    position: 'Ingeniero Comercial',
    position1: 'Director de Operaciones',
    img: '/assets/images/about-us/team/carlos-harbie.jpg',
  }, {
    name: 'Emily Simancas',
    position: 'Arquitecta',
    position1: 'Gerente de Ventas',
    img: '/assets/images/about-us/team/emily-simancas.jpg',
  }, {
    name: 'John Phillips',
    position: 'Diseñador de Ambientes',
    position1: 'Asesor de Proyectos',
    img: '/assets/images/about-us/team/john-phillips.jpg',
  }, {
    name: 'Andrea Maschio',
    position: 'Diseñadora de Ambientes',
    position1: 'Asesor de Proyectos',
    img: '/assets/images/about-us/team/andrea-maschio.jpg',
  }, {
      name: 'Fernanda Domel',
      position: 'Diseñador de Ambientes',
      position1: 'Asesor de Proyectos',
      img: '/assets/images/about-us/team/fernanda-domel.jpg',
  }, {
    name: 'Danny Zambrano',
    position: 'Asesor E-commerce',
    position1: 'Coordinador de Operaciones',
    img: '/assets/images/about-us/team/danny-zambrano.jpg'
  }, {
    name: 'Esthefany Yores',
    position: 'Diseñadora',
    position1: 'Departamento de Marketing',
    img: '/assets/images/about-us/team/esthefany-yores.jpg',
  }, {
    name: 'Eliana Guanipa',
    position: 'Eliana',
    position1: 'Departamento de RRHH y Cobranzas',
    img: '/assets/images/about-us/team/eliana-guanipa.jpg',
  }, {
    name: 'Solimar Delgado',
    position: 'Solimar',
    position1: 'Departamento de Compras',
    img: '/assets/images/about-us/team/solimar-delgado.jpg',
  }, {
    name: 'Carlos Cabezas',
    position: 'Carlos',
    position1: 'Coordinador de Logística',
    img: '/assets/images/about-us/team/carlos-cabezas.jpg',
  }, {
    name: 'Jorge Fonseca',
    position: 'Jorge',
    position1: 'Instalación',
    img: '/assets/images/about-us/team/jorge-fonseca.jpg',
  }, {
    name: 'Oscar Barnuevo',
    position: 'Oscar',
    position1: 'Instalación',
    img: '/assets/images/about-us/team/oscar-barnuevo.jpg',
  }];

  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Acerca de nosotros - Muebles de oficina | Dunati',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: 'Conozca nuestro equipo y cómo seguimos ampliando los límites del diseño mientras creamos un mundo mejor. Explore nuestra historia para aprender más sobre Dunati.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/nosotros`,
        },
        {
          property: 'og:title',
          content: 'Acerca de nosotros - Muebles de oficina | Dunati',
        },
        {
          property: 'og:description',
          content: 'Conozca nuestro equipo y cómo seguimos ampliando los límites del diseño mientras creamos un mundo mejor. Explore nuestra historia para aprender más sobre Dunati.',
        },
        {
          property: 'og:image',
          content:  `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/nosotros`,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/nosotros`
          }
        ]
      }
      */
    };

    this.$options.dunatiJsonld = DUNATI_ORGANIZATION_SCHEMA;
  }

  public mounted() {
    this.setHeight();
    window.addEventListener('resize', this.getHeight);
  }

  public created() {
  }

  public destroyed() {
    window.removeEventListener('resize', this.getHeight);
  }

  public getHeight(event: Event) {
    this.setHeight();
  }

  public setHeight(): void {
    const ctn1 = (this.$refs.ctn1 as any).clientHeight;
    const ctn2 = (this.$refs.ctn2 as any).clientHeight;
    const ctn3 = (this.$refs.ctn3 as any).clientHeight;
    this.vHeight = ctn1 + ctn2 + ctn3 + 1250;
  }
}

